<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Budget Overview
            </h2>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="5"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mobile_create_button"
          @click="exportBudgetOverview()"
        >
          <feather-icon
            icon="CheckSquareIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Export</span>
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col
          md="5"
          lg="5"
          class="overview__option-block"
        >
          <div class="overview__option-title mb-2">
            <h4>Overview Options</h4>
          </div>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Cost Centre"
                label-for="cost-center"
                label-cols-md="4"
              >
                <v-select
                  id="cost-center"
                  v-model="costCenter"
                  label="name"
                  placeholder="Select Cost Centre"
                  :options="costCenterOptions"
                  :reduce="name => name._id"
                  :clearable="false"
                  @input="submitOverviewOption()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Budgetary Period"
                label-for="budgetary-period"
                label-cols-md="4"
              >
                <v-select
                  id="budgetary-period"
                  v-model="budgetaryPeriod"
                  label="name"
                  placeholder="Select Budgetary Period"
                  :options="budgetaryPeriodOptions"
                  :reduce="name => name._id"
                  :clearable="false"
                  @input="submitOverviewOption()"
                >
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #option="{ name, start, end }">
                    <span style="font-size: 14px;font-weight: 400;"> {{ name }} ({{ `${dateFormat(start)} - ${dateFormat(end)}` }})</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Expense Category Type"
                label-for="expense-category-type"
                label-cols-md="4"
              >
                <v-select
                  id="expense-category-type"
                  v-model="expenseCategoryType"
                  label="name"
                  placeholder="Select from list"
                  :options="expenseCategoryTypeOptions"
                  :reduce="index => index"
                  :clearable="false"
                  @input="submitOverviewOption()"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="7"
          lg="7"
        >
          <div class="overview__option-result-block">
            <div class="overview__option-result-title d-flex justify-content-between">
              <h4>{{ costCenter ? resolveCostCenterName(costCenter): '-' }}</h4>
              <h5>{{ budgetaryPeriod ? resolveBudgetaryPeriodDateAndName(budgetaryPeriod): '-' }}</h5>
            </div>
            <div class="overview__option-result-budget-amount card-statistics statistics-body my-2">
              <b-row>
                <b-col
                  v-if="expenseCategoryType !== 'Non-budgeted'"
                >
                  <b-media
                    no-body
                    class="m-25"
                  >
                    <b-media-aside
                      class="mr-1"
                    >
                      <b-img
                        width="48"
                        height="48"
                        :src="require('@/assets/images/admin/budget_icon.png')"
                        rounded="circle"
                        alt="Circle image"
                      />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ allocated > 0 ? `$${numberFormat(allocated)}` : '-' }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Allocated Budget
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media
                    no-body
                    class="m-25"
                  >
                    <b-media-aside
                      class="mr-1"
                    >
                      <b-avatar
                        size="48"
                        variant="light-info"
                      >
                        <feather-icon
                          size="24"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ spent > 0 ? `$${numberFormat(spent)}` : '-' }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Spent
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  v-if="expenseCategoryType !== 'Non-budgeted'"
                >
                  <b-media
                    no-body
                    class="m-25"
                  >
                    <b-media-aside
                      class="mr-1"
                    >
                      <b-avatar
                        size="48"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="24"
                          icon="PieChartIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ remaining > 0 ? `$${numberFormat(remaining)}` : '-' }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Balance
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </div>
            <div
              v-if="expenseCategoryType !== 'Non-budgeted'"
              class="overview__option-budget-progress progress-wrapper"
            >
              <b-card-text class="mb-0 d-flex justify-content-between">
                <span class="text-muted">Budget Usage %</span>
                <h3 class="text-primary">
                  {{ usagePercentage > 0 ? `${numberFormat(usagePercentage)}%` : '-' }}
                </h3>
              </b-card-text>
              <b-progress
                v-model="usagePercentage"
                max="100"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectAllWorkFlowCheckbox"
                  :indeterminate="isSelectAllWorkFlowCheckboxIndeterminate"
                  :disabled="!selectedWorkFlows.length"
                  @change="selectAllCheckboxUpdate"
                />
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                variant="primary"
                :disabled="!selectedWorkFlows.length"
                @click="selectedExpenseDetailsPage()"
              >
                View Related Expenses
              </b-button>
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectAllWorkFlowCheckbox"
                :indeterminate="isSelectAllWorkFlowCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              />
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              variant="primary"
              :disabled="!selectedWorkFlows.length"
              @click="selectedExpenseDetailsPage()"
            >
              View Related Expenses
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="expenseCategoryType !== 'Non-budgeted'"
        ref="refAllExpenseTable"
        class="position-relative event_list"
        :items="budgetItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(code)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedWorkFlows.includes(data.item._id)"
              @change="toggleSelectedWorkFlows(data.item._id)"
              @click.native.stop
            >
              <span class="finance__code-color">{{ data.item.code ? data.item.code : '-' }}</span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(expenseCategoryName)="data">
          <b-button
            v-if="canViewThisAction('see-expense', 'BudgetOverview')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            @click="singleExpenseDetailsPage(data.item._id)"
          >
            {{ data.item.expenseCategoryName }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id not-button"
          >
            {{ data.item.expenseCategoryName }}
          </b-button>
        </template>

        <template #cell(allocated)="data">
          <span class="white-space-no-wrap">$ {{ numberFormat(data.item.allocated) }}</span>
        </template>

        <template #cell(spent)="data">
          <span class="white-space-no-wrap">$ {{ numberFormat(data.item.spent) }}</span>
        </template>

        <template #cell(remaining)="data">
          <span class="white-space-no-wrap">$ {{ numberFormat(data.item.remaining) }}</span>
        </template>

        <template #cell(usagePercentage)="data">
          <div>
            <b-progress
              v-b-tooltip.hover.v-primary.top="`${numberFormat(data.item.usagePercentage)}%`"
              max="100"
            >
              <b-progress-bar
                :value="data.item.usagePercentage"
                variant="primary"
              />
            </b-progress>
          </div>
        </template>
      </b-table>
      <b-table
        v-else
        ref="refAllNonBudgetExpenseTable"
        class="position-relative event_list"
        :items="nonBudgetItems"
        responsive
        :fields="nonBudgetTableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(code)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedWorkFlows.includes(data.item.expenseCategory._id)"
              @change="toggleSelectedWorkFlows(data.item.expenseCategory._id)"
              @click.native.stop
            >
              <span class="finance__code-color">{{ data.item.expenseCategory.code ? data.item.expenseCategory.code : '-' }}</span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(expenseCategoryName)="data">
          <b-button
            v-if="canViewThisAction('see-expense', 'BudgetOverview')"
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
            @click="singleExpenseDetailsPage(data.item.expenseCategory._id)"
          >
            {{ data.item.expenseCategory.name }}
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="none"
            class="text-bold-black align-middle detail-view-id not-button"
          >
            {{ data.item.expenseCategory.name }}
          </b-button>
        </template>

        <template #cell(spent)="data">
          <span class="white-space-no-wrap">$ {{ numberFormat(data.item.sum) }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, VBTooltip, BCard, BFormGroup, BProgress, BProgressBar,
  BMedia, BMediaAside, BAvatar, BMediaBody, BCardText, BImg,
  BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import vSelect from 'vue-select'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { writeFile, utils } from 'xlsx'
// import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BProgress,
    BProgressBar,
    BMedia,
    BMediaAside,
    BAvatar,
    BImg,
    BMediaBody,
    BCardText,
    BFormInput,
    BTable,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      costCenter: '',
      budgetaryPeriod: '',
      expenseCategoryType: '',
      costCenterOptions: [],
      budgetaryPeriodOptions: [],
      expenseCategoryTypeOptions: [
        'Operating Expenses',
        'CAPEX (Fixed Assets)',
        'CAPEX (Lease Assets)',
        'Projects',
        'Non-budgeted',
      ],
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      // budget result object
      showSearchBar: false,
      searchQuery: '',
      manipulateBudgetItems: [],
      allocated: 0,
      spent: 0,
      remaining: 0,
      usagePercentage: 0,
      selectedWorkFlows: [],
      expenseItems: [],
      hasBudgetPeriodOptions: [],
      allPeriodOptions: [],
      // validation rules
      required,
      sortBy: 'code',
      sortDesc: false,
      isSortDirDesc: false,
      nonBudgetItems: [],
      tableColumns: [
        {
          key: 'code',
          label: 'budget codes',
          sortable: true,
        },
        {
          key: 'expenseCategoryName',
          label: 'sub budget (expense categories)',
          sortable: true,
        },
        {
          key: 'allocated',
          label: 'allocated budget',
          sortable: true,
        },
        {
          key: 'spent',
          label: 'spent',
          sortable: true,
        },
        {
          key: 'remaining',
          label: 'balance',
          sortable: true,
        },
        {
          key: 'usagePercentage',
          label: 'budget usage',
          sortable: true,
        },
      ],
      nonBudgetTableColumns: [
        {
          key: 'code',
          label: 'budget codes',
          sortable: true,
        },
        {
          key: 'expenseCategoryName',
          label: 'sub budget (expense categories)',
          sortable: true,
        },
        {
          key: 'spent',
          label: 'spent',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    selectAllWorkFlowCheckbox() {
      if (this.expenseCategoryType !== 'Non-budgeted') {
        return this.manipulateBudgetItems.length && (this.budgetItems.length === this.selectedWorkFlows.length)
      }
      return this.nonBudgetItems.length && (this.nonBudgetItems.length === this.selectedWorkFlows.length)
    },
    isSelectAllWorkFlowCheckboxIndeterminate() {
      if (this.expenseCategoryType !== 'Non-budgeted') {
        return Boolean(this.selectedWorkFlows.length) && this.budgetItems.length !== this.selectedWorkFlows.length
      }
      return Boolean(this.selectedWorkFlows.length) && this.nonBudgetItems.length !== this.selectedWorkFlows.length
    },
    budgetItems() {
      return this.manipulateBudgetItems.filter(item => item.code.includes(this.searchQuery) || (item.expenseCategoryName.toLowerCase() && item.expenseCategoryName.toLowerCase().includes(this.searchQuery.toLowerCase())) || (item.allocated && item.allocated.toString().includes(this.searchQuery)) || (item.spent && item.spent.toString().includes(this.searchQuery)) || (item.remaining && item.remaining.toString().includes(this.searchQuery)) || (item.usagePercentage && item.usagePercentage.toString().includes(this.searchQuery)))
    },
    // nonBudgetItems() {
    //   return this.manipulateBudgetItems.filter(item => item.code.includes(this.searchQuery) || (item.expenseCategoryName.toLowerCase() && item.expenseCategoryName.toLowerCase().includes(this.searchQuery.toLowerCase())) || (item.spent && item.spent.toString().includes(this.searchQuery)))
    // },
  },

  beforeMount() {
    this.$http.get('budget/cost-center/all/respond-with/name-and-id', { params: { department: true } })
      .then(response => {
        this.costCenterOptions = response.data.costCenters
        if (this.costCenterOptions.length) {
          if (localStorage.getItem('overviewNewCostCenter')) {
            this.costCenter = localStorage.getItem('overviewNewCostCenter')
          } else {
            this.costCenter = this.costCenterOptions[0]._id
          }
        }
        this.submitOverviewOption()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('budget/budget-period/has-budget/respond-with/name-and-id')
      .then(response => {
        if (localStorage.getItem('overviewNewExpenseCategoryType')) {
          this.expenseCategoryType = localStorage.getItem('overviewNewExpenseCategoryType')
        } else {
          this.expenseCategoryType = 'Operating Expenses'
        }
        if (this.expenseCategoryType === 'Non-budgeted') {
          this.budgetaryPeriodOptions = response.data.allPeriods
        } else {
          this.budgetaryPeriodOptions = response.data.budgetPeriods
        }
        this.hasBudgetPeriodOptions = response.data.budgetPeriods
        this.allPeriodOptions = response.data.allPeriods
        if (this.budgetaryPeriodOptions.length) {
          if (localStorage.getItem('overviewNewBudgetaryPeriod')) {
            this.budgetaryPeriod = localStorage.getItem('overviewNewBudgetaryPeriod')
          } else {
            this.budgetaryPeriod = this.budgetaryPeriodOptions[0]._id
          }
        }
        this.submitOverviewOption()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    // this.submitOverviewOption()
  },

  methods: {
    toggleSelectedWorkFlows(id) {
      const workFlowIndex = this.selectedWorkFlows.indexOf(id)
      if (workFlowIndex === -1) this.selectedWorkFlows.push(id)
      else this.selectedWorkFlows.splice(workFlowIndex, 1)
    },
    selectAllCheckboxUpdate(val) {
      if (this.expenseCategoryType !== 'Non-budgeted') {
        this.selectedWorkFlows = val ? this.budgetItems.map(flow => flow._id) : []
      } else {
        this.selectedWorkFlows = val ? this.nonBudgetItems.map(flow => flow.expenseCategory._id) : []
      }
    },
    singleExpenseDetailsPage(item) {
      this.expenseItems = []
      this.expenseItems.push(item)
      localStorage.setItem('expenseItems', JSON.stringify(this.expenseItems))
      this.$router.push({ name: 'budgeting-budget-overview-item-expenses' })
    },
    selectedExpenseDetailsPage() {
      this.expenseItems = []

      if (this.expenseCategoryType !== 'Non-budgeted') {
        this.selectedWorkFlows.forEach(item => {
          const singleItem = this.manipulateBudgetItems.find(stockObj => stockObj._id === item)
          this.expenseItems.push(singleItem._id)
        })
      } else {
        this.expenseItems = this.selectedWorkFlows
      }
      if (this.expenseItems.length) {
        localStorage.setItem('expenseItems', JSON.stringify(this.expenseItems))
        this.$router.push({ name: 'budgeting-budget-overview-item-expenses' })
      }
    },
    resolveCostCenterName(id) {
      const found = this.costCenterOptions.find(ob => ob._id === id)
      if (found) {
        return found.name
      }
      return ''
    },
    resolveBudgetaryPeriodDateAndName(id) {
      const found = this.budgetaryPeriodOptions.find(ob => ob._id === id)
      if (found) {
        return `${found.name} (${this.dateFormat(found.start)} - ${this.dateFormat(found.end)})`
      }
      return ''
    },
    exportBudgetOverview() {
      // eslint-disable-next-line eqeqeq
      if (this.costCenter != '' && this.budgetaryPeriod != '' && this.expenseCategoryType != '') {
        if (this.expenseCategoryType !== 'Non-budgeted') {
          const costCenter = this.costCenterOptions.find(o => o._id === this.costCenter)
          const budgetaryPeriod = this.budgetaryPeriodOptions.find(o => o._id === this.budgetaryPeriod)
          const worksheet = utils.aoa_to_sheet([
            ['Cost Centre:', `${costCenter.name}`],
            ['Budgetary Period:', `${budgetaryPeriod.name} (${this.dateFormat(budgetaryPeriod.start)} - ${this.dateFormat(budgetaryPeriod.end)})`],
            ['Expense Category Type:', `${this.expenseCategoryType}`],
            [],
            [],
            ['Budget Codes', 'Sub Budgets (Expense Category)', 'Allocated Budget', 'Spent', 'Balance', 'Budget Usages Percentage'],
          ])

          const data = []
          this.manipulateBudgetItems.forEach(obj => {
            data.push({
              code: obj.code ? obj.code : '-',
              expenseCategoryName: obj.expenseCategoryName,
              allocated: `$${this.numberFormat(obj.allocated)}`,
              spent: `$${this.numberFormat(obj.spent)}`,
              remaining: `$${this.numberFormat(obj.remaining)}`,
              usagePercentage: `${this.numberFormat(obj.usagePercentage)}%`,
            })
          })
          data.push({
            code: '',
            expenseCategoryName: 'Total:',
            allocated: this.allocated > 0 ? `$${this.numberFormat(this.allocated)}` : '-',
            spent: this.spent > 0 ? `$${this.numberFormat(this.spent)}` : '-',
            remaining: this.remaining > 0 ? `$${this.numberFormat(this.remaining)}` : '-',
            usagePercentage: this.usagePercentage > 0 ? `${this.numberFormat(this.usagePercentage)}%` : '-',
          })
          utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 })
          worksheet['!cols'] = [{ wch: 15 }, { wch: 25 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }]
          const workbook = utils.book_new()
          utils.book_append_sheet(workbook, worksheet, 'Budget Overview')
          writeFile(workbook, `Budget Overview - ${this.dateFormat(new Date())}.xlsx`, { compression: true })
        } else {
          const costCenter = this.costCenterOptions.find(o => o._id === this.costCenter)
          const budgetaryPeriod = this.budgetaryPeriodOptions.find(o => o._id === this.budgetaryPeriod)
          const worksheet = utils.aoa_to_sheet([
            ['Cost Centre:', `${costCenter.name}`],
            ['Budgetary Period:', `${budgetaryPeriod.name} (${this.dateFormat(budgetaryPeriod.start)} - ${this.dateFormat(budgetaryPeriod.end)})`],
            ['Expense Category Type:', `${this.expenseCategoryType}`],
            [],
            [],
            ['Budget Codes', 'Sub Budgets (Expense Category)', 'Spent'],
          ])

          const data = []
          this.nonBudgetItems.forEach(obj => {
            data.push({
              code: obj.expenseCategory.code ? obj.expenseCategory.code : '-',
              expenseCategoryName: obj.expenseCategory.name,
              spent: `$${this.numberFormat(obj.sum)}`,
            })
          })
          data.push({
            code: '',
            expenseCategoryName: 'Total:',
            spent: this.spent > 0 ? `$${this.numberFormat(this.spent)}` : '-',
          })
          utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 })
          worksheet['!cols'] = [{ wch: 15 }, { wch: 25 }, { wch: 10 }]
          const workbook = utils.book_new()
          utils.book_append_sheet(workbook, worksheet, 'Budget Overview')
          writeFile(workbook, `Budget Overview - ${this.dateFormat(new Date())}.xlsx`, { compression: true })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select the Overview Options First',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    submitOverviewOption() {
      this.nonBudgetItems = []
      this.manipulateBudgetItems = []
      // eslint-disable-next-line eqeqeq
      if (this.costCenter != '' && this.budgetaryPeriod != '' && this.expenseCategoryType != '') {
        localStorage.setItem('overviewNewCostCenter', this.costCenter)
        localStorage.setItem('overviewNewBudgetaryPeriod', this.budgetaryPeriod)
        localStorage.setItem('overviewNewExpenseCategoryType', this.expenseCategoryType)
        if (this.expenseCategoryType !== 'Non-budgeted') {
          this.budgetaryPeriodOptions = this.hasBudgetPeriodOptions
          localStorage.setItem('nonBudgeted', false)
          const exists = this.budgetaryPeriodOptions.find(o => o._id === this.budgetaryPeriod)
          if (!exists) {
            this.budgetaryPeriod = ''
            localStorage.setItem('overviewNewBudgetaryPeriod', this.budgetaryPeriod)
          } else {
            const formData = new FormData()
            formData.append('costCenter', this.costCenter)
            formData.append('budgetPeriod', this.budgetaryPeriod)
            formData.append('expenseCategoryType', this.expenseCategoryType)
            this.$http.post('budget/budget/find', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                const { budgetItems } = response.data
                // this.allocated = budget.allocated
                // this.spent = budget.spent
                // this.remaining = budget.remaining
                // this.usagePercentage = budget.usagePercentage
                const departmentArray = this.user.department.map(({ _id }) => _id)
                this.manipulateBudgetItems = budgetItems.filter(o => {
                  const filtered = o.expenseCategory.departments.filter(d => departmentArray.includes(d))
                  return filtered.length
                })
                this.allocated = this.manipulateBudgetItems.reduce((n, { allocated }) => n + allocated, 0)
                this.spent = this.manipulateBudgetItems.reduce((n, { spent }) => n + spent, 0)
                this.remaining = this.manipulateBudgetItems.reduce((n, { remaining }) => n + remaining, 0)
                this.usagePercentage = (this.spent * 100) / this.allocated
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.allocated = 0
                this.spent = 0
                this.remaining = 0
                this.usagePercentage = 0
                this.manipulateBudgetItems = []
              })
          }
        } else {
          this.budgetaryPeriodOptions = this.allPeriodOptions
          localStorage.setItem('nonBudgeted', true)
          const formData = new FormData()
          formData.append('costCenter', this.costCenter)
          formData.append('budgetPeriod', this.budgetaryPeriod)
          formData.append('expenseCategoryType', this.expenseCategoryType)
          this.$http.post('budget/budget/find/non-budgeted', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              const { budget } = response.data
              // this.spent = budget.reduce((n, { sum }) => n + sum, 0)
              const departmentArray = this.user.department.map(({ _id }) => _id)
              this.nonBudgetItems = budget.filter(o => {
                const filtered = o.expenseCategory.departments.filter(d => departmentArray.includes(d))
                return filtered.length
              })
              this.spent = this.nonBudgetItems.reduce((n, { sum }) => n + sum, 0)
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              this.allocated = 0
              this.spent = 0
              this.remaining = 0
              this.usagePercentage = 0
              this.manipulateBudgetItems = []
            })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.overview__option-block{
      border-right: 1px solid #D2DCEA;
}

.b-avatar.badge-light-info {
    background: rgba(0, 207, 232, 0.12);
    color: #00CFE8;
}

@media (max-width: 767px) {
   .overview__option-block{
      border-right: none;
      border-bottom: 1px solid #D2DCEA;
      margin-bottom: 1rem;
}
}

.finance__code-color{
   color: #333333;
}
.white-space-no-wrap {
  white-space: nowrap;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
